import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import ReactInputMask from 'react-input-mask';

function InputMasked({ name, id, label, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        ref.setInputValue(value);
      },
      clearValue(ref) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <ReactInputMask
        ref={inputRef}
        name={name}
        id={id}
        placeholder={label}
        {...rest}
      />
      {error && <span className="error">{error}</span>}
    </>
  );
}

export default InputMasked;
