import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import imgLogoC6BankPng from '../../assets/images/logo-c6bank.png';
import imgWhatsAppSvg from '../../assets/images/whatsapp.svg';
import imgMessengerSvg from '../../assets/images/messenger.svg';
import imgInstagramPng from '../../assets/images/instagram.png';
import imgFacebookPng from '../../assets/images/facebook.png';
import imgTwitterPng from '../../assets/images/twitter.png';

import { useCustomer } from '../../providers/CustomerProvider';

function OfferCard({ offer }) {
  const history = useHistory();
  const { customer, order, setOrder } = useCustomer();

  const handleOfferSelection = offer => {
    const selectedOrder = { customer, offer };
    setOrder(selectedOrder);
    localStorage.setItem('order', JSON.stringify(selectedOrder));

    history.push('/pagamento');
  };

  useEffect(() => {
    if (order) {
      localStorage.removeItem('order');
    }
  }, []);

  return (
    <div className="box">
      <div className="blue">
        <div className="name_offer">
          {offer.name}
          <hr />
        </div>
        <h2>{offer.title}</h2>
        <div className="bonus-bank">
          <h3>
            <div dangerouslySetInnerHTML={{ __html: offer.description }} />
            {offer.has_c6 && (
              <img
                src={imgLogoC6BankPng}
                width="140"
                height="28"
                alt="C6Bank"
              />
            )}
          </h3>
        </div>
        {offer.apps && (
          <div className="apps">
            <h3>Apps Inclusos</h3>
            <img src={imgWhatsAppSvg} width="50" height="50" alt="WhatsApp" />
            <img src={imgMessengerSvg} width="50" height="50" alt="Messenger" />
          </div>
        )}
        <div className="ligacoes">
          <h3 dangerouslySetInnerHTML={{ __html: offer.calls }} />
        </div>
        {offer.social && (
          <div className="redes-sociais">
            <h4>{offer.social.description}</h4>
            <img src={imgInstagramPng} alt="Instagram" />
            <img src={imgFacebookPng} alt="Facebook" />
            <img src={imgTwitterPng} alt="Twitter" />
          </div>
        )}
        <div className="price">
          <u>
            de R$ {String(offer.price).replace('.', ',')}/MÊS
            <hr />
          </u>
          R${' '}
          <strong>
            {String(offer.total.toFixed(2)).replace('.', ',')}
            <i>*</i>
          </strong>
          /MÊS
          <small>*Valor válido para pagamento</small>
          <span>
            via fatura{' '}
            <em>
              {offer.permanency
                ? 'Fidelização de 12 meses'
                : 'sem permanência mínima'}
            </em>
          </span>
        </div>
        <button
          type="button"
          onClick={() => handleOfferSelection(offer)}
          className="selecionar"
          id={`contratar_${offer.title}`}
        >
          contratar agora
        </button>
        <div className="beneficios">
          <h6 className="tool" data-tip={offer.benefits}>
            <strong>+</strong> mais benefícios
          </h6>
        </div>
        <a
          href={offer.rules_file}
          rel="noreferrer"
          target="_blank"
          className="regulamento"
        >
          <strong>Regulamento</strong> da oferta TIM Controle
        </a>
      </div>
    </div>
  );
}

export default OfferCard;
