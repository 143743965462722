import React, { useEffect } from 'react';

import '../../assets/css/responsive.css';
import '../../assets/css/style.css';

import imgLogoPng from '../../assets/images/logo.png';
import imgC6BankPng from '../../assets/images/logo-c6bank.png';
import imgAndroidPng from '../../assets/images/android.png';
import imgApplePng from '../../assets/images/apple.png';

function Success() {
  useEffect(() => {
    window.scrollTo(0, 0);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(JSON.parse(localStorage.getItem('dataLayer')));

    localStorage.removeItem('dataLayer');
  }, []);

  return (
    <div className="success">
      <header>
        <div className="container">
          <div className="logo">
            <img src={imgLogoPng} width="105" height="30" alt="Logo" />
          </div>
        </div>
      </header>

      <section className="mensagem">
        <div className="container">
          <div className="row">
            <div className="grid_6">
              <h1>
                Parabéns por escolher o <strong>TIM Controle!</strong> Recebemos
                seu pedido e ele será processado em até 72h.
              </h1>
              <h2>
                Não esqueça! Abra sua conta no{' '}
                <img src={imgC6BankPng} width="140" height="28" alt="C6Bank" />{' '}
                e ganhe mais <strong>4GB de dados</strong> na sua oferta TIM
                Controle.
                <a
                  href="https://www.c6bank.com.br/"
                  rel="noreferrer"
                  target="_blank"
                >
                  https://www.c6bank.com.br
                </a>
                <br />
                <span> Baixar o App:</span>
                <a
                  href="https://play.google.com/store/apps/details?id=com.c6bank.app"
                  target="_blank"
                  rel="noreferrer"
                  className="app_bank"
                >
                  <img src={imgAndroidPng} alt="Android" />
                </a>
                <a
                  href="https://apps.apple.com/br/app/c6-bank/id1463463143"
                  target="_blank"
                  rel="noreferrer"
                  className="app_bank"
                >
                  <img src={imgApplePng} alt="Apple" />
                </a>
              </h2>
            </div>
          </div>
        </div>
      </section>

      <footer className="m2b-footer">
        <div className="container">
          <h6>
            Desenvolvido por
            <a href="https://mobi2buy.com/" rel="noreferrer" target="_blank">
              Mobi2buy
            </a>
          </h6>
        </div>
      </footer>
    </div>
  );
}

export default Success;
