import * as yup from 'yup';
import { parse, isValid, isAfter, isBefore, subYears } from 'date-fns';

yup.addMethod(yup.string, 'isValidCpf', function (message) {
  return this.test({
    message,
    name: 'isValidCpf',
    exclusive: true,
    test(value) {
      const strCPF = value.replace(/\D/g, '').padStart(11, '0');

      let Soma;
      let Resto;

      Soma = 0;
      if (strCPF === '00000000000') {
        return false;
      }

      for (let i = 1; i <= 9; i++) {
        Soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      }

      Resto = (Soma * 10) % 11;

      if (Resto === 10 || Resto === 11) {
        Resto = 0;
      }

      if (Resto !== parseInt(strCPF.substring(9, 10))) {
        return false;
      }

      Soma = 0;

      for (let i = 1; i <= 10; i++) {
        Soma += parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      }

      Resto = (Soma * 10) % 11;

      if (Resto === 10 || Resto === 11) {
        Resto = 0;
      }

      if (Resto !== parseInt(strCPF.substring(10, 11))) {
        return false;
      }

      return true;
    },
  });
});

yup.addMethod(yup.string, 'isValidCep', function (message) {
  return this.test({
    message,
    name: 'isValidCep',
    exclude: true,
    test(value) {
      const cep = value.replace(/\D/g, '');
      return cep.length === 8;
    },
  });
});

yup.addMethod(yup.string, 'isValidaDate', function (message) {
  return this.test({
    message,
    name: 'isValidaDate',
    exclusive: true,
    test(value) {
      const data = parse(value, 'dd/MM/yyyy', new Date());
      const dataMinima = subYears(new Date(), 18);
      const dataMaxima = subYears(new Date(), 100);

      if (!isValid(data)) {
        return false;
      }

      if (isAfter(data, dataMinima) || isBefore(data, dataMaxima)) {
        return false;
      }

      return true;
    },
  });
});

yup.addMethod(yup.string, 'isTinyName', function (message) {
  return this.test({
    message,
    name: 'isTinyName',
    exclusive: true,
    test(value) {
      const parsedValue = value.trim().split(' ');
      let isValidName = true;

      if (parsedValue.length <= 2) {
        parsedValue.forEach(currentValue => {
          if (currentValue.length < 3) {
            isValidName = false;
          }
        });
      }

      return parsedValue.length >= 2 && isValidName;
    },
  });
});

yup.addMethod(yup.string, 'isCellPhone', function (message) {
  return this.test({
    message,
    name: 'isCellPhone',
    exclusive: true,
    test(value) {
      const parsedValue = value.trim().replace(/\D/g, '');

      // Verifying if it is a cellphone number
      if (parsedValue.length === 11) {
        const ddd = parsedValue.substring(0, 2) * 1;

        // Does not exists DDD smaller than 11 in Brazil
        if (ddd < 11) {
          return false;
        }

        const thisNumber = parsedValue.substring(2);
        const firstDigit = thisNumber.substring(1, 0) * 1;

        if (firstDigit !== 9) {
          // It is not a valid cellphone in Brazil
          return false;
        }

        return true;
      }

      return false;
    },
  });
});

export const loginSchema = {
  msisdn: yup
    .string()
    .isCellPhone('Insira um número TIM com DDD válido')
    .required('Insira um número TIM com DDD válido'),
};

export const checkoutSchema = {
  name: yup
    .string()
    .isTinyName('Informe seu nome completo')
    .required('Informe seu nome completo'),
  cpf: yup.string().isValidCpf('CPF inválido').required('Informe seu CPF'),
  birthday: yup
    .string()
    .isValidaDate('Informe uma data válida')
    .required('Informe sua data de nascimento'),
  mother: yup
    .string()
    .isTinyName('Informe o nome completo de sua mãe')
    .required('Informe o nome completo de sua mãe'),
  email: yup
    .string()
    .email('Informe um e-mail válido')
    .required('Informe seu e-mail'),
  cep: yup
    .string()
    .isValidCep('Informe um CEP válido')
    .required('Informe seu CEP'),
  number: yup.string().required('Informe o número do seu endereço'),
  is_debit: yup.boolean(),
  bank: yup.string().when('is_debit', {
    is: true,
    then: yup.string().required('Informe o seu Banco'),
    otherwise: yup.string(),
  }),
  agency: yup.string().when('is_debit', {
    is: true,
    then: yup.string().required('Informe o número de sua agência'),
    otherwise: yup.string(),
  }),
  account: yup.string().when('is_debit', {
    is: true,
    then: yup.string().required('Informe o número de sua conta'),
    otherwise: yup.string(),
  }),
  maturity: yup.string().required('Informe um dia de vencimento'),
};
