import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

function Select({ name, label, options, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      <select
        ref={inputRef}
        name={name}
        id={name}
        defaultValue={defaultValue}
        {...rest}
      >
        <option value="">{label}</option>
        {options.map(item => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      {error && <span className="error">{error}</span>}
    </>
  );
}

export default Select;
