import React, { useState } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useCustomer } from '../../providers/CustomerProvider';

function RedirectRoute({ component: Component, ...rest }) {
  const location = useLocation();
  const { customer } = useCustomer();

  return (
    <Route
      {...rest}
      render={props => {
        if (customer) {
          return <Component {...props} />;
        }

        return (
          <Redirect
            to={{
              pathname: '/',
              state: {
                from: location,
              },
            }}
          />
        );
      }}
    />
  );
}

export default RedirectRoute;
