import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const AppContext = createContext();

function CustomerProvider({ children }) {
  const location = useLocation();
  const [customer, setCustomer] = useState(() => {
    return JSON.parse(localStorage.getItem('customer')) || false;
  });
  const [order, setOrder] = useState(() => {
    return JSON.parse(localStorage.getItem('order')) || false;
  });
  const [showErrorOverlay, setShowErrorOverlay] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Erro desconhecido');
  const [utmSource, setUtmSource] = useState('website');

  const getDDDFromCustomer = () => {
    const ddd = String(customer.msisdn)
      .replace(/[^0-9]+/g, '')
      .substring(0, 2);
    return ddd;
  };

  useEffect(() => {
    const currentPath = location.pathname + location.search;

    window.dataLayer.push({
      event: 'pageview',
      page: {
        path: currentPath,
      },
    });
  }, [location]);

  return (
    <AppContext.Provider
      value={{
        customer,
        order,
        showErrorOverlay,
        errorMessage,
        setCustomer,
        setOrder,
        getDDDFromCustomer,
        setShowErrorOverlay,
        setErrorMessage,
        utmSource,
        setUtmSource,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export const useCustomer = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error(
      'You must surround your components with a CustomerProvider before call useCustomer',
    );
  }

  return context;
};

export default CustomerProvider;
