import React, { useRef, useEffect, useState } from 'react';
import { Form } from '@unform/web';
import * as yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import '../../assets/css/responsive.css';
import '../../assets/css/style.css';

import imgLogoPng from '../../assets/images/logo.png';
import InputMasked from '../../components/InputMasked';
import MyLoadingOverlay from '../../components/MyLoadingOverlay';
import ErrorOverlay from '../../components/ErrorOverlay';
import { loginSchema } from '../../utils/schemas';
import { useCustomer } from '../../providers/CustomerProvider';

import api from '../../services/api';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Login() {
  const formRef = useRef(null);
  const history = useHistory();
  const query = useQuery();
  const {
    showErrorOverlay,
    setCustomer,
    setShowErrorOverlay,
    setErrorMessage,
    setUtmSource,
  } = useCustomer();
  const [utmTags, setUtmTags] = useState('utm_source=website');
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async data => {
    try {
      setIsProcessing(true);

      // Remove all previous errors
      formRef.current.setErrors({});

      const schema = yup.object().shape(loginSchema);

      await schema.validate(data, {
        abortEarly: false,
      });

      localStorage.setItem('customer', JSON.stringify(data));

      setCustomer(data);

      const { msisdn } = data;

      await api.post('/api/v1/tim-ura-dma/leads', { msisdn, utms: utmTags });

      setIsProcessing(false);

      history.push('/ofertas');
    } catch (err) {
      const validationErrors = {};

      if (err instanceof yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      } else {
        setErrorMessage(err.message);
        setShowErrorOverlay(true);
      }

      setIsProcessing(false);
    }
  };

  const handleMsisdnChange = async e => {
    const msisdn = e.currentTarget.value.replace(/[\D]+/g, '');

    if (msisdn.length >= 11) {
      // Remove all previous errors
      formRef.current.setErrors({});

      const schema = yup.object().shape({
        msisdn: yup
          .string()
          .isCellPhone('Insira um número TIM com DDD válido')
          .required('Insira um número TIM com DDD válido'),
      });

      try {
        await schema.validate(
          { msisdn },
          {
            abortEarly: false,
          },
        );
      } catch (err) {
        const validationErrors = {};

        if (err instanceof yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });

          formRef.current.setErrors(validationErrors);
        }
      }
    }
  };

  useEffect(() => {
    const utm_source = query.get('utm_source');
    const utm_medium = query.get('utm_medium');

    if (utm_source) {
      setUtmTags(`utm_source=${utm_source}&utm_medium=${utm_medium}`);
      setUtmSource(utm_source);
    }
  }, []);

  return (
    <div className="login">
      <header>
        <div className="container">
          <div className="logo">
            <img src={imgLogoPng} width="105" height="30" alt="Logo" />
          </div>
        </div>
      </header>

      <div className="banner">
        <div className="container">
          <div className="row">
            <div className="col grid_6">
              <h2>Conheça o novo TIM Controle</h2>
              <h3>Uma parceria da TIM com um banco digital completo.</h3>
              <p>
                <picture>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAAAXCAMAAAB57vBWAAAAe1BMVEVHcEz/////////////////////Eyv/////FCz/////////////////////FCz/b3r/////////FS3/////////////////////////////////////////Eyv/////Ey3/FCz/FCz/FCv/Eyz/FCv/Eyz/Eyv/////FCwlSSYxAAAAJ3RSTlMAiPQqBzMJcOvtaFx8S+QD150QlBY+srwi4qfJdtz6cyP2qVCtos+lLZObAAAD30lEQVRYw72XC7ebKBCAwcgaRKpc8G3tpu1d8/9/YWEGUNM0j97NnXNy4vjA+ZinhLxY0l7s9B+n0/G6nN/xDrrwrSQzI6Tr52SujL0q58SeG8q4YNnMnC8teTnInO307+c/y79wx7BciCSkhoODvVrguXV3BOjJ60GSfKf//CsQFkEyPLd6YASde40JIVh5YYLx/13XHczjppv0Fsj3/wdkYX499BWCpAVHpQgmpG1fVYn0MdtqpZSOKDTEipbRvTn+YE+GmyA/Tscv12XNkd7KDBbZA84P10C0X0+tIIxH9r7DqxCITTDcPU+yJjisChfaKpicFYRMBfqjrW+GFvnnj+JfkFohaeVMyFC5BpLgxpokghy2XuRAcnDbwc0OhGg0lOTZ5E0tBr0ByQr0pbidIw9IWVokAMmdQq6CLGjOtESQcRePkETtri54kNqD6E5S71Wjs0uQoSAfBkG/o0dCDv8OUq23AQhWL54zgRFW+4JWsS7dgQg0n2liFDpLdWXL9iBU36ta5DKUVv05EFcDvPUAMsQa0CWYRCWsMc8LR6cUmDgtpvZkF6d4QTEisQzmHqQY7pbfmOzH8zenv/13Cvr7wyB973PYxU6fAAg8oHDPFaUqXymXBZygtcjzvPL2aLuaVAHEu8B7RDT3+8im/CLI8bL8PgCiKaouv5OJAwi4Ydq+qoGigPXPeUE1BaV0oOAYCYYPLICQRgSQPO3pUw3x70GGGtPd8TQMQ6uPO0/qKc+nOuVYhyU8sYaWgPpLZfCAB+nGDnWhlKH0czzSQkyNpStUskMQqFCVsxFzQ5tQsgZfGnyyE2otMoOs65phDAEIxJMDkbOtE428myPnMDR6kK/noP98OEdGiH9OEzdkeY/gENbYFtRgLSh9AYcQ24IImxmipUprRcc6ghBNASR33qjb9G7VegsCZaqM6lv5BEgZurjCIYWH1phU+N8TCLakxlYzbEBcimvfC4VeQdK2E7GPTOoz+shIID2wXUjfELNdQxTrLSHZA0iRERbMTIcugpB6mPIAQprsc0D8QGKPAsjOcPdkWkUV2gKWKyJt+MV5EY4CiE0PEUFMy26DlEF8Q4x6+RSIb4DZBoTIEG8cU9WMuwlTqXyactocbKrHEZgNoQiDG2gEscC3k/345StKaIhHr5/enwLB1DBbENcd2rFdp3JSW70pfEixIsuySdoNM+tmlywlh5jYxq5nuvCweXn5HeE9Qgp3ZgtyfQi9M6N++AvxgyBB7oK8/Jv9Jki/mzjWb/bpOsgnfLPzy4Z4fKghNgvn66dExxM+9wbmXXt+N5uyJOHL+AvnKcqbDdZ95gAAAABJRU5ErkJggg=="
                    alt="TIM Controle"
                    title="TIM Controle"
                  />
                </picture>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container grid_8">
        <Form
          ref={formRef}
          onSubmit={data => handleSubmit(data)}
          className="grid_5"
        >
          <h2>Bem-vindo ao TIM Controle</h2>
          <InputMasked
            type="tel"
            name="msisdn"
            id="msisdn"
            label="Seu número TIM com DDD"
            mask="(99)99999-9999"
            onChange={e => handleMsisdnChange(e)}
          />
          <button type="submit">Conferir novas ofertas</button>
        </Form>
      </div>

      <div className="m2b-footer no-footer">
        <div className="container">
          <h6>
            Desenvolvido por
            <a href="https://mobi2buy.com/" rel="noreferrer" target="_blank">
              Mobi2buy
            </a>
          </h6>
        </div>
      </div>

      {showErrorOverlay && <ErrorOverlay />}
      {isProcessing && <MyLoadingOverlay />}
    </div>
  );
}

export default Login;
