import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';

function Checkbox({ name, id, label, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: ref => {
        return ref.checked;
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <input ref={inputRef} type="checkbox" name={name} id={id} {...rest} />
      <label htmlFor={id}>{label}</label>
    </>
  );
}

export default Checkbox;
