import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import RedirectRoute from '../RedirectRoute';
import CustomerProvider from '../../providers/CustomerProvider';

import Home from '../../pages/Home';
import Login from '../../pages/Login';
import Offers from '../../pages/Offers';
import Success from '../../pages/Success';

function MainRoutes() {
  return (
    <Router>
      <CustomerProvider>
        <Switch>
          <Route path="/" exact component={Login} />
          <RedirectRoute path="/ofertas" exact component={Offers} />
          <RedirectRoute path="/pagamento" exact component={Home} />
          <RedirectRoute path="/sucesso" exact component={Success} />
        </Switch>
      </CustomerProvider>
    </Router>
  );
}

export default MainRoutes;
