import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';

import '../../assets/css/responsive.css';
import '../../assets/css/style.css';

import imgLogoPng from '../../assets/images/logo.png';
import imgWhatsAppSvg from '../../assets/images/whatsapp.svg';
import imgMessengerSvg from '../../assets/images/messenger.svg';
import imgInstagramPng from '../../assets/images/instagram.png';
import imgFacebookPng from '../../assets/images/facebook.png';
import imgTwitterPng from '../../assets/images/twitter.png';

import Input from '../../components/Input';
import InputMasked from '../../components/InputMasked';
import RadioPaymentDay from '../../components/RadioPaymentDay';
import Checkbox from '../../components/Checkbox';
import Select from '../../components/Select';
import MyLoadingOverlay from '../../components/MyLoadingOverlay';
import ErrorOverlay from '../../components/ErrorOverlay';

import { useCustomer } from '../../providers/CustomerProvider';
import { checkoutSchema } from '../../utils/schemas';

import api from '../../services/api';

function Home() {
  const formRef = useRef(null);
  const history = useHistory();
  const [selectedPaymentDay, setSelectedPaymentDay] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [address, setAddress] = useState({
    logradouro: '',
    bairro: '',
    cidade: '',
    uf: '',
  });
  const [isDebitPayment, setIsDebitPayment] = useState(true);
  const {
    order,
    customer,
    showErrorOverlay,
    setShowErrorOverlay,
    setErrorMessage,
    utmSource,
  } = useCustomer();
  const paymentDays = [
    { value: '01', label: '01' },
    { value: '07', label: '07' },
    { value: '10', label: '10' },
    { value: '12', label: '12' },
    { value: '15', label: '15' },
    { value: '20', label: '20' },
  ];
  const bankList = [
    { label: '001 - Banco do Brasil', value: '001' },
    { label: '003 - Banco da Amazônia', value: '003' },
    { label: '004 - Banco do Nordeste do Brasil', value: '004' },
    { label: '021 - Banco Banestes', value: '021' },
    { label: '033 - Santander', value: '033' },
    { label: '037 - Banpará', value: '037' },
    { label: '041 - Banrisul', value: '041' },
    { label: '047 - Banese', value: '047' },
    { label: '070 - BRB Banco de Brasília SA', value: '070' },
    { label: '077 - Banco Inter', value: '077' },
    { label: '084 - Uniprime', value: '084' },
    { label: '085 - Cecred', value: '085' },
    { label: '104 - Caixa Econômica Federal', value: '104' },
    { label: '212 - Banco Original', value: '212' },
    { label: '237 - Bradesco', value: '237' },
    { label: '341 - Itaú', value: '341' },
    { label: '389 - Mercantil', value: '389' },
    { label: '422 - Safra', value: '422' },
    { label: '745 - Citybank', value: '745' },
    { label: '748 - Bansicredi', value: '748' },
    { label: '756 - Bancoob', value: '756' },
    { label: '988 - Credicorol', value: '988' },
    { label: '996 - Coop de Crédito Rural Coopavel', value: '996' },
    { label: '997 - Credicoamo', value: '997' },
  ];

  const handlePaymentDaySelection = e => {
    setSelectedPaymentDay(e.currentTarget.value);
  };

  const handleDebitPaymentSelection = () => {
    setIsDebitPayment(!isDebitPayment);
  };

  const handleKeyPress = event => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/^[0-9\b]+$/.test(keyValue) === false) {
      event.preventDefault();
    }
  };

  const handleSubmitCheckout = async data => {
    try {
      setIsProcessing(true);
      setIsSubmitted(true);

      // Remove all previous errors
      formRef.current.setErrors({});

      const schema = yup.object().shape(checkoutSchema);

      await schema.validate(data, {
        abortEarly: false,
      });

      const eavsData = Object.assign(data, { source: utmSource });

      const { msisdn } = customer;
      const orderData = {
        eavs: eavsData,
        msisdn,
        product_offered: order.offer.code,
      };

      api
        .post('/api/v1/tim-ura-dma/orders', orderData)
        .then(response => {
          localStorage.removeItem('order');
          localStorage.removeItem('customer');
          setIsProcessing(false);

          localStorage.setItem(
            'dataLayer',
            JSON.stringify({
              ecommerce: {
                purchase: {
                  actionField: {
                    id: `${msisdn}`,
                    affiliation: 'Controle TIM URA/DMA',
                    revenue: (
                      order.offer.price - order.offer.discount.value
                    ).toFixed(2),
                    tax: 0.0,
                    shipping: 0.0,
                    coupon: '',
                  },
                  products: [
                    {
                      name: order.offer.title,
                      id: order.offer.code,
                      price: (
                        order.offer.price - order.offer.discount.value
                      ).toFixed(2),
                      category: 'Plano de Celular',
                      brand: 'TIM',
                      variant: '',
                      quantity: 1,
                      coupon: '',
                    },
                  ],
                },
              },
            }),
          );

          history.push('/sucesso');
        })
        .catch(err => {
          setIsProcessing(false);
          setIsSubmitted(false);
          setErrorMessage(err.message);
          setShowErrorOverlay(true);
          console.log(err.message);
        });
    } catch (err) {
      const validationErrors = {};

      if (err instanceof yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      } else {
        setErrorMessage(err.message);
        setShowErrorOverlay(true);
      }

      setIsProcessing(false);
      setIsSubmitted(false);
    }
  };

  const handleCepChange = async e => {
    const cep = e.currentTarget.value.replace(/[\D]+/g, '');
    if (cep.length >= 8) {
      setIsProcessing(true);
      await api
        .get(`api/v1/cep/${cep}`)
        .then(response => {
          const { success } = response.data;

          if (success) {
            setAddress(response.data);
          } else {
            setErrorMessage('CEP inválido');
            setShowErrorOverlay(true);
          }
        })
        .catch(err => {
          setErrorMessage(err.message);
          setShowErrorOverlay(true);
        });

      setIsProcessing(false);
    }
  };

  return (
    <div className="home">
      <section className="popup boleto">
        <div className="head">
          <img src={imgLogoPng} width="105" height="30" alt="Logo" />
        </div>
        <div className="form-group grid_10">
          <Form
            ref={formRef}
            onSubmit={data => handleSubmitCheckout(data)}
            autoComplete="off"
            noValidate="novalidate"
          >
            <div className="row gutters">
              <div className="col grid_3">
                <h4>Plano escolhido:</h4>
                <div className="plan">
                  <h2>
                    {order.offer.title}
                    <span>{order.offer.plain_description}</span>
                  </h2>
                  {order.offer.apps && (
                    <div className="apps">
                      <h3>Apps Inclusos</h3>
                      <img src={imgWhatsAppSvg} alt="Whatsapp" />
                      <img src={imgMessengerSvg} alt="Messenger" />
                    </div>
                  )}
                  <div className="ligacoes">
                    <h3
                      dangerouslySetInnerHTML={{ __html: order.offer.calls }}
                    />
                  </div>
                  {order.offer.social && (
                    <div className="redes-sociais">
                      <h5>{order.offer.social.description}</h5>
                      <img src={imgInstagramPng} alt="Instagram" />
                      <img src={imgFacebookPng} alt="Facebook" />
                      <img src={imgTwitterPng} alt="Twitter" />
                    </div>
                  )}
                  <div className="price">
                    R$
                    <strong>
                      {String(order.offer.total.toFixed(2)).replace('.', ',')}
                      <i>*</i>
                    </strong>
                    /MÊS
                    <br />
                    <small>*Valor válido para pagamento</small>
                    <span>
                      via fatura{' '}
                      <em>
                        {order.offer.permanency
                          ? 'Fidelização de 12 meses'
                          : 'sem permanência mínima'}
                      </em>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col grid_6">
                <h4>Seus dados</h4>
                <p>
                  <Input type="text" name="name" id="name" label="Seu nome" />
                </p>
                <div className="row gutters">
                  <div className="col grid_6">
                    <p>
                      <InputMasked
                        type="tel"
                        name="cpf"
                        id="cpf"
                        label="CPF"
                        mask="999.999.999-99"
                        // onChange={e => handleCpfChange(e)}
                      />
                    </p>
                  </div>
                  <div className="col grid_6">
                    <p>
                      <InputMasked
                        type="tel"
                        name="birthday"
                        id="birthday"
                        label="Data de Nascimento"
                        mask="99/99/9999"
                      />
                    </p>
                  </div>
                </div>
                <p>
                  <Input type="email" name="email" id="email" label="E-mail" />
                </p>
                <p>
                  <Input
                    type="text"
                    name="mother"
                    id="mother"
                    label="Nome da Mãe"
                  />
                </p>
                <br />
                <h4>Seu endereço</h4>
                <div className="row gutters">
                  <p className="col grid_4">
                    <InputMasked
                      type="tel"
                      name="cep"
                      id="cep"
                      mask="99999-999"
                      label="CEP"
                      onChange={e => handleCepChange(e)}
                    />
                  </p>
                  <p className="col grid_8">
                    <Input
                      type="text"
                      name="address"
                      id="address"
                      label="Rua, Avenida, Travesa..."
                      onChange={() => {}}
                      value={address.logradouro}
                      readOnly
                    />
                  </p>
                </div>
                <div className="row gutters">
                  <p className="col grid_4">
                    <Input
                      type="tel"
                      name="number"
                      id="number"
                      label="Número"
                      onKeyPress={e => handleKeyPress(e)}
                    />
                  </p>
                  <p className="col grid_8">
                    <Input
                      type="text"
                      name="complement"
                      id="complement"
                      label="Complemento"
                    />
                  </p>
                </div>
                <div className="row gutters">
                  <p className="col grid_4">
                    <Input
                      type="text"
                      name="neighborhood"
                      id="neighborhood"
                      label="Bairro"
                      onChange={() => {}}
                      value={address.bairro}
                      readOnly
                    />
                  </p>
                  <p className="col grid_4">
                    <Input
                      type="text"
                      name="city"
                      id="city"
                      label="Cidade"
                      onChange={() => {}}
                      value={address.cidade}
                      readOnly
                    />
                  </p>
                  <p className="col grid_4">
                    <Input
                      type="text"
                      name="state"
                      id="state"
                      maxLength="2"
                      label="Estado"
                      onChange={() => {}}
                      value={address.uf.toUpperCase()}
                      readOnly
                    />
                  </p>
                </div>
              </div>
              <div className="col grid_3">
                <h4>Dados para a fatura:</h4>
                <p>
                  <Checkbox
                    type="checkbox"
                    name="is_debit"
                    id="is_debit"
                    label="Fatura em débito automático."
                    onChange={() => handleDebitPaymentSelection()}
                    defaultChecked
                  />
                </p>
                <br />

                {isDebitPayment && (
                  <>
                    <div>
                      <p>
                        <Select label="Banco" name="bank" options={bankList} />
                      </p>
                      <div className="row gutters">
                        <p className="col grid_6">
                          <Input
                            type="tel"
                            name="agency"
                            id="agency"
                            label="Agência"
                            maxLength="5"
                            onKeyPress={e => handleKeyPress(e)}
                          />
                        </p>
                        <p className="col grid_6">
                          <Input
                            type="tel"
                            name="account"
                            id="account"
                            label="Conta e dígito"
                            maxLength="12"
                            onKeyPress={e => handleKeyPress(e)}
                          />
                        </p>
                      </div>
                    </div>
                    <br />
                  </>
                )}

                <h4>Escolha o vencimento:</h4>
                <div className="vencimento row gutters">
                  <RadioPaymentDay
                    onChange={e => handlePaymentDaySelection(e)}
                    options={paymentDays}
                    name="maturity"
                    id="maturity"
                  />
                </div>
                <p>
                  <input
                    type="submit"
                    id="FINALIZAR_PEDIDO"
                    value="FINALIZAR PEDIDO"
                    disabled={isSubmitted}
                  />
                </p>
              </div>
            </div>
          </Form>
        </div>
      </section>

      <footer className="m2b-footer">
        <div className="container">
          <h6>
            Desenvolvido por
            <a href="https://mobi2buy.com/" rel="noreferrer" target="_blank">
              Mobi2buy
            </a>
          </h6>
        </div>
      </footer>

      {showErrorOverlay && <ErrorOverlay />}
      {isProcessing && <MyLoadingOverlay />}
    </div>
  );
}

export default Home;
