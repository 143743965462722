import React from 'react';

import imgSadPng from '../../assets/images/sad.png';
import { useCustomer } from '../../providers/CustomerProvider';

function ErrorOverlay() {
  const { errorMessage, setShowErrorOverlay } = useCustomer();

  const handleOverlayCloseButton = e => {
    e.preventDefault();
    setShowErrorOverlay(false);
  };

  return (
    <section id="popup" className="popup-erro">
      <div className="content-popup grid_4">
        <h4>
          <i>
            <img src={imgSadPng} width="64" height="64" alt="Error" />
          </i>
          <span>{errorMessage}</span>
        </h4>
        <br />
        <br />
        <a href="/" onClick={e => handleOverlayCloseButton(e)} className="card">
          Tentar de novo
        </a>
      </div>
    </section>
  );
}

export default ErrorOverlay;
