import React, { useRef, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import Slider from 'react-slick';
import { useHistory } from 'react-router-dom';

import '../../assets/css/responsive.css';
import '../../assets/css/slick.css';
import '../../assets/css/slick-theme.css';
import '../../assets/css/style.css';

import imgLogoPng from '../../assets/images/logo.png';
import imgIcoFacebookPng from '../../assets/images/icon-facebook-big.png';
import imgIcoInstagramPng from '../../assets/images/icon-instagram-big.png';
import imgIcoTwitterPng from '../../assets/images/icon-twitter-big.png';
import imgIcoWhatsAppPng from '../../assets/images/icon-whatsapp-big.png';
import imgIcoTelegramPng from '../../assets/images/icon-telegram-big.png';
import imgIcoMessengerPng from '../../assets/images/icon-messenger-big.png';
import imgLogoC6BankPng from '../../assets/images/logo-c6bank.png';
import imgAndroidPng from '../../assets/images/android.png';
import imgApplePng from '../../assets/images/apple.png';

import OfferCard from '../../components/OfferCard';
import MyLoadingOverlay from '../../components/MyLoadingOverlay';
import ErrorOverlay from '../../components/ErrorOverlay';

import api from '../../services/api';
import { useCustomer } from '../../providers/CustomerProvider';

const slickProps = {
  dots: false,
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

function Offers() {
  const history = useHistory();
  const formRef = useRef(null);
  const [offers, setOffers] = useState([]);
  const [banner, setBanner] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const {
    showErrorOverlay,
    getDDDFromCustomer,
    setCustomer,
    customer,
    setOrder,
    setShowErrorOverlay,
    setErrorMessage,
  } = useCustomer();

  const handleOfferSelection = offer => {
    const selectedOrder = { customer, offer };
    setOrder(selectedOrder);
    localStorage.setItem('order', JSON.stringify(selectedOrder));

    history.push('/pagamento');
  };

  const handleChangeNumber = e => {
    e.preventDefault();
    localStorage.removeItem('order');
    localStorage.removeItem('customer');

    setCustomer(null);
    setOrder(null);

    history.push('/');
  };

  const handleSubmitForm = data => {
    handleOfferSelection(banner);
  };

  useEffect(() => {
    const fetchOffers = async () => {
      const ddd = getDDDFromCustomer();

      setIsProcessing(true);

      const response = await api
        .get(`api/v1/tim-ura-dma/offers?ddd=${ddd}`)
        .catch(err => {
          console.log(err);
          setErrorMessage(err.message);
          setShowErrorOverlay(true);
        });

      setOffers(response.data);

      const bannerIndexFound = response.data.findIndex((offer, index) => {
        return offer.best_offer === true;
      });

      if (bannerIndexFound !== -1) {
        setBanner(response.data[bannerIndexFound]);
      }

      setIsProcessing(false);
    };
    fetchOffers();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ msisdn: customer.msisdn });
  }, []);

  return (
    <>
      <header>
        <div className="container">
          <div className="col grid_1">
            <img src={imgLogoPng} width="105" height="30" alt="Logo" />
          </div>
          <div className="col numero-login">
            <p>{customer.msisdn}</p>
            <a href="/" onClick={e => handleChangeNumber(e)}>
              Trocar número
            </a>
          </div>
        </div>
      </header>

      <div className="banner">
        <div className="container">
          <div className="row">
            <div className="col grid_7">
              {banner && (
                <Form ref={formRef} onSubmit={data => handleSubmitForm(data)}>
                  <h2>
                    Conheça o novo
                    <span>TIM Controle</span>
                  </h2>
                  <h3>Uma parceria da TIM com um banco digital completo.</h3>
                  <p>
                    <picture>
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAAAXCAMAAAB57vBWAAAAe1BMVEVHcEz/////////////////////Eyv/////FCz/////////////////////FCz/b3r/////////FS3/////////////////////////////////////////Eyv/////Ey3/FCz/FCz/FCv/Eyz/FCv/Eyz/Eyv/////FCwlSSYxAAAAJ3RSTlMAiPQqBzMJcOvtaFx8S+QD150QlBY+srwi4qfJdtz6cyP2qVCtos+lLZObAAAD30lEQVRYw72XC7ebKBCAwcgaRKpc8G3tpu1d8/9/YWEGUNM0j97NnXNy4vjA+ZinhLxY0l7s9B+n0/G6nN/xDrrwrSQzI6Tr52SujL0q58SeG8q4YNnMnC8teTnInO307+c/y79wx7BciCSkhoODvVrguXV3BOjJ60GSfKf//CsQFkEyPLd6YASde40JIVh5YYLx/13XHczjppv0Fsj3/wdkYX499BWCpAVHpQgmpG1fVYn0MdtqpZSOKDTEipbRvTn+YE+GmyA/Tscv12XNkd7KDBbZA84P10C0X0+tIIxH9r7DqxCITTDcPU+yJjisChfaKpicFYRMBfqjrW+GFvnnj+JfkFohaeVMyFC5BpLgxpokghy2XuRAcnDbwc0OhGg0lOTZ5E0tBr0ByQr0pbidIw9IWVokAMmdQq6CLGjOtESQcRePkETtri54kNqD6E5S71Wjs0uQoSAfBkG/o0dCDv8OUq23AQhWL54zgRFW+4JWsS7dgQg0n2liFDpLdWXL9iBU36ta5DKUVv05EFcDvPUAMsQa0CWYRCWsMc8LR6cUmDgtpvZkF6d4QTEisQzmHqQY7pbfmOzH8zenv/13Cvr7wyB973PYxU6fAAg8oHDPFaUqXymXBZygtcjzvPL2aLuaVAHEu8B7RDT3+8im/CLI8bL8PgCiKaouv5OJAwi4Ydq+qoGigPXPeUE1BaV0oOAYCYYPLICQRgSQPO3pUw3x70GGGtPd8TQMQ6uPO0/qKc+nOuVYhyU8sYaWgPpLZfCAB+nGDnWhlKH0czzSQkyNpStUskMQqFCVsxFzQ5tQsgZfGnyyE2otMoOs65phDAEIxJMDkbOtE428myPnMDR6kK/noP98OEdGiH9OEzdkeY/gENbYFtRgLSh9AYcQ24IImxmipUprRcc6ghBNASR33qjb9G7VegsCZaqM6lv5BEgZurjCIYWH1phU+N8TCLakxlYzbEBcimvfC4VeQdK2E7GPTOoz+shIID2wXUjfELNdQxTrLSHZA0iRERbMTIcugpB6mPIAQprsc0D8QGKPAsjOcPdkWkUV2gKWKyJt+MV5EY4CiE0PEUFMy26DlEF8Q4x6+RSIb4DZBoTIEG8cU9WMuwlTqXyactocbKrHEZgNoQiDG2gEscC3k/345StKaIhHr5/enwLB1DBbENcd2rFdp3JSW70pfEixIsuySdoNM+tmlywlh5jYxq5nuvCweXn5HeE9Qgp3ZgtyfQi9M6N++AvxgyBB7oK8/Jv9Jki/mzjWb/bpOsgnfLPzy4Z4fKghNgvn66dExxM+9wbmXXt+N5uyJOHL+AvnKcqbDdZ95gAAAABJRU5ErkJggg=="
                        alt="TIM Controle"
                        title="TIM Controle"
                      />
                    </picture>
                  </p>
                  <div className="giga-desconto">
                    <p className="giga">{banner.title}</p>
                    <span className="line" />
                    <p className="desconto">
                      Por apenas
                      <br />
                      <em>R$</em>
                      <strong>
                        {String(banner.total.toFixed(2)).replace('.', ',')}
                      </strong>
                      /MÊS
                    </p>
                  </div>
                  <button type="submit">Contratar</button>
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container">
          {offers.length && (
            <Slider {...slickProps}>
              {offers.map(offer => (
                <OfferCard key={offer.code} offer={offer} />
              ))}
            </Slider>
          )}
        </div>
      </div>

      <footer>
        <div className="container">
          <h4>
            Apps inclusos no seu plano
            <span>
              Veja quais apps fazem parte da sua oferta e continue usando mesmo
              depois que a sua internet acabar
            </span>
          </h4>
          <div className="row gutters">
            <div className="col grid_4">
              <i>
                <img
                  src={imgIcoFacebookPng}
                  width="66"
                  height="66"
                  alt="Facebook"
                />
              </i>
              <p>
                <strong>Facebook</strong> Compartilhe links, fotos e vídeos,
                faça e assista Lives e interaja com seus amigos sem limites.
              </p>
            </div>
            <div className="col grid_4">
              <i>
                <img
                  src={imgIcoInstagramPng}
                  width="66"
                  height="66"
                  alt="Instagram"
                />
              </i>
              <p>
                <strong>Instagram</strong> Faça fotos e vídeos, abuse dos
                stories, escolha os melhores filtros e explore seus temas
                prediletos.
              </p>
            </div>
            <div className="col grid_4">
              <i>
                <img
                  src={imgIcoTwitterPng}
                  width="66"
                  height="66"
                  alt="Twitter"
                />
              </i>
              <p>
                <strong>Twitter</strong> Acompanhe as últimas notícias, seus
                perfis favoritos e publique textos, fotos, gifs, lives e vídeos.
              </p>
            </div>
          </div>
          <div className="row gutters">
            <div className="col grid_4">
              <i>
                <img
                  src={imgIcoWhatsAppPng}
                  width="66"
                  height="66"
                  alt="WhatsApp"
                />
              </i>
              <p>
                <strong>WhatsApp</strong> Mande mensagens, fotos, vídeos e
                áudios a qualquer hora e em todo lugar.
              </p>
            </div>
            <div className="col grid_4">
              <i>
                <img
                  src={imgIcoTelegramPng}
                  width="66"
                  height="66"
                  alt="Telegram"
                />
              </i>
              <p>
                <strong>Telegram</strong> Mensagens de texto, fotos e stickers à
                vontade em um só aplicativo.
              </p>
            </div>
            <div className="col grid_4">
              <i>
                <img
                  src={imgIcoMessengerPng}
                  width="66"
                  height="66"
                  alt="Messenger"
                />
              </i>
              <p>
                <strong>Messenger</strong> Converse com seus amigos sempre e o
                quanto quiser.
              </p>
            </div>
            <div className="col grid_3 vazio">&nbsp;</div>
          </div>
          <div className="bank-c6">
            Baixe o App e abra sua conta no
            <img src={imgLogoC6BankPng} width="140" height="28" alt="C6Bank" />
            <a
              href="https://www.c6bank.com.br/"
              rel="noreferrer"
              target="_blank"
            >
              https://www.c6bank.com.br
            </a>
            <br />
            Baixar o App:
            <br />
            <a
              href="https://play.google.com/store/apps/details?id=com.c6bank.app"
              target="_blank"
              className="app_bank"
              rel="noreferrer"
            >
              <img src={imgAndroidPng} alt="Android" />
            </a>
            <a
              href="https://apps.apple.com/br/app/c6-bank/id1463463143"
              target="_blank"
              className="app_bank"
              rel="noreferrer"
            >
              <img src={imgApplePng} alt="Apple" />
            </a>
          </div>
        </div>
      </footer>

      {showErrorOverlay && <ErrorOverlay />}
      {isProcessing && <MyLoadingOverlay />}
    </>
  );
}

export default Offers;
